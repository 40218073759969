require('es6-promise').polyfill();
import 'nodelist-foreach-polyfill';

import { tns } from "../node_modules/tiny-slider/src/tiny-slider"
import tabs from './modules/tabs';

window.addEventListener('DOMContentLoaded', () => {
  try {
    tabs('.tabs-head', '.tabs-content', '.tabs-headers', 'active');
  } catch (error) {}

  // sliders
  try {
    const slider = tns({
      container: '.hero-slider',
      items: 1,
      slideBy: 1, 
      autoplay: false,
      mouseDrag: true,
      controls: true,
      gutter: 30,
      nav: false,
      controlsContainer: "#customize-controls",
      responsive: {
        1024: {
          gutter: 0
        }
      }  
    });
  } catch (error) {}
  
});  